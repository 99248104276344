import { graphql, useStaticQuery } from "gatsby"

export const useGetProductConfigurations = () => {
  const { allMdx } = useStaticQuery(
    graphql`
      query productConfigurations {
        allMdx(
          filter: { frontmatter: { identification: { eq: "spoorConfig" } } }
        ) {
          edges {
            node {
              frontmatter {
                spoor {
                  feature
                  feature_business
                  feature_professional
                  feature_starter
                  identification
                }
                specialCargo {
                  feature
                  feature_business
                  feature_professional
                  feature_starter
                  identification
                }
                productSubtitle1st
                productSubtitle2nd
                productTitle1st
                productTitle2nd
                sectionHeader
                sectionSubheading
              }
            }
          }
        }
      }
    `
  )

  return allMdx.edges[0].node.frontmatter
}
