import React from "react"
import { ResponsiveContext } from "grommet"

import { useGetProductConfigurations } from "../hooks/useGetProductConfigurations"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Landing from "../components/Landing"

import PricingContact from "./pricing/PricingContact"
import PricingTabs from "./pricing/PricingTabs"

const Pricing = () => {
  const data = useGetProductConfigurations()

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          <Layout>
            <SEO title="Pricing" />
            <Landing
              title="Simple, flexible pricing"
              subTitle="Spoor is highly customizable to fit your specific needs"
              transition="gray"
            />

            <PricingTabs data={data} size={size} />
            <PricingContact size={size} />
          </Layout>
        </>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default Pricing
